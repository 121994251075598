// Here you can add other styles
.centered-ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date-filter {
  width: 40%;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .date-filter {
    width: 100%;
  }
}

.leaderboard-filters-ctn .form-label {
  margin-right: 1%;
}
.leaderboard-filters-ctn .form-control {
  margin-right: 1%;
}

.breakword {
  word-break: break-all !important;
}

// Here you can add other styles
.my-error-text {
  color: var(--cui-danger)
}

.my-card:hover {
  // border-width: 4px;
  // box-shadow: 5px 10px;
  outline: 3px solid black;
}

.translations-group-container {
  padding: 10px;
}

.dynamicForm-array-container {
  padding: 10px;
}

.accordion-remove-button {
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100%;
}

.itinerary-form-submit-button {
  margin-top: 10px;
}

.dynamic-form-new-array-item-button {
  margin-left: 10px;
}

.form-add-subitem-button {
  margin-bottom: 10px;
}

.published-label,
.draft-label {
  padding: 5px 10px;
  border-radius: 100px;
  text-align: center;
}

.published-label {
  background-color: var(--cui-success);
}

.draft-label {
  background-color: var(--cui-warning);
}

.sync-alert {
  position: fixed;
  top: 20px;
  z-index: 9999;
}

.upload-img {
  max-width: 100%;
  height: 150px;
  margin: auto;
  display: block;
}


.dashboard-qr-img {
  width: 500px;
}

.stripe-btn {
  width: 15rem;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:hover {
    opacity: .89;
  }
}